import { CSmartTable } from "@coreui/react-pro";
import { useState } from "react";
import { RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { SupplyType } from "../../../types";
import CIcon from "@coreui/icons-react";
import { cilPen } from "@coreui/icons";
import { deleteSupplyTypes, getSupplyTypes } from "../../../services/ProductService";
import { setSupplyTypes } from "../../../redux/slices/ProductSlice";
import DeleteItemModal from "../modals/DeleteItemModal";
import EditSupplyTypeModal from "../modals/EditSupplyTypeModal";
import { useTranslation } from "react-i18next";
import { Utils } from "../utils";

interface SupplyTypeProps {
  showEditedToast: () => void;
  showDeletedToast: () => void;
};

const AllSuppliesTypes = (props: SupplyTypeProps) => {
    const {showDeletedToast, showEditedToast} = props;   
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {AllSuppliesTypeColumns} = Utils();
    const supplyTypes = useSelector((state: RootState) => state.productSlice.supplyTypes);
    const [showEditSupplyTypeModal, setShowEditSupplyTypeModal] = useState<boolean>(false);
    const [selectedSupplyTypeItem, setSelectedSupplyTypeItem] = useState<SupplyType>();
    const [showDeleteSupplyTypeModal, setShowDeleteSupplyTypeModal] = useState<boolean>(false);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [activePage, setActivePage] = useState(1);

   

    const getAllSupplyTypes = () => {
        getSupplyTypes().then((response) => {
          dispatch(setSupplyTypes(response.data));
          setShowEditSupplyTypeModal(false);
          setShowDeleteSupplyTypeModal(false);
        }).catch((e) => {
          dispatch(setSupplyTypes([]));
          console.log('get products supply type error: ', e);
        });
    }
    const deleteSupplyTypeItem = () => {
        deleteSupplyTypes(selectedSupplyTypeItem ? selectedSupplyTypeItem.id : 0).then((response) => {
          getAllSupplyTypes();
          showDeletedToast();
        }).catch((e) => {
        })
    }
    return (
    <div>
         {
            showDeleteSupplyTypeModal ?
              <DeleteItemModal 
                  isModalVisible={showDeleteSupplyTypeModal}
                  headerText={"Are you sure?"}
                  bodyText={"This will delete the item permanently."}
                  closeModal={() => setShowDeleteSupplyTypeModal(false)}
                  confirmButtonText={"Delete"}
                  onClickConfirmation={deleteSupplyTypeItem}
              /> : null
        }
        {
            showEditSupplyTypeModal ?
              <EditSupplyTypeModal 
                  isModalVisible={showEditSupplyTypeModal}
                  headerText={"Edit supply type."}
                  bodyText={"Please edit the following details of the supply type."}
                  selectedSupplyTypeItem={selectedSupplyTypeItem}
                  closeModal={() => setShowEditSupplyTypeModal(false)}
                  confirmButtonText={"Edit"}
                  onClickConfirmation={() => {getAllSupplyTypes(); showEditedToast();}}
              /> : null
        }
        <CSmartTable
          columns={AllSuppliesTypeColumns}
          tableFilter
          tableFilterLabel={`${t('adminDashboard.CommonWords.search')}`}
          tableFilterPlaceholder={`${t('adminDashboard.CommonWords.search')}`}
          items={supplyTypes}
          itemsPerPage={itemsPerPage}
          itemsPerPageSelect
          pagination={true}
          paginationProps={{
            activePage: activePage,
            pages: Math.ceil(supplyTypes.length / itemsPerPage) || 1,
          }}
          tableHeadProps={{
            color: 'info',
          }}
          tableProps={{
            hover: true,
            responsive: true,
            style:{marginTop: 12},
          }}
          onActivePageChange={(activePage) => setActivePage(activePage)}
          onColumnFilterChange={(filter) => {
            setActivePage(1)
          }}
          onItemsPerPageChange={(itemsPerPage) => {
            setActivePage(1)
            setItemsPerPage(itemsPerPage)
          }}
          itemsPerPageLabel={`${t('adminDashboard.CommonWords.items_per_page')}`}
          scopedColumns={{
            show_details: (item: SupplyType) => {
              return (
                <td className="py-2 text-center" style={{ textAlign: 'center' }}>
                  <CIcon icon={cilPen} size="xl" style={{color: '#23B2D9', marginLeft: 24}} onClick={() => {setSelectedSupplyTypeItem(item);  setShowEditSupplyTypeModal(true);}}/>                         
                </td>
              )
            },
            grade_A_percent: (item: SupplyType) => {
              return (
                <td className="py-2 text-center">
                  {(item.grade_A_percent * 100)?.toFixed(2)}
                </td>
              )
            },
            grade_B_percent: (item: SupplyType) => {
              return (
                <td className="py-2 text-center">
                  {(item.grade_B_percent * 100)?.toFixed(2)}
                </td>
              )
            },
            grade_C_percent: (item: SupplyType) => {
              return (
                <td className="py-2 text-center">
                  {(item.grade_C_percent * 100)?.toFixed(2)}
                </td>
              )
            },
            buying_percentage: (item: SupplyType) => {
              return (
                <td className="py-2 text-center">
                  {item.buying_percentage * 100}
                </td>
              )
            },
            supplier: (item: SupplyType) => {
              return (
                <td className="py-2 text-center">
                  {item.supplier}
                </td>
              )
            },
            name: (item: SupplyType) => {
              return (
                <td className="py-2 text-center">
                  {item.name}
                </td>
              )
            },
            id: (item: SupplyType) => {
              return (
                <td className="py-2 text-center">
                  {item.id}
                </td>
              )
            },
          }}
        />
    </div>
    )
};
export default AllSuppliesTypes;
