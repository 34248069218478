import { cilCheck, cilPen } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CCard, CCardBody, CCardTitle, CCardText, CBadge, CButton, CSpinner } from "@coreui/react";
import { Pallet } from "../../../types";
import Divider from "../divider";
import { useState } from "react";

interface PalletCardComponentProps {
    cardOnClick: (value: () => void) => void;
    selectedPallets: any;
    item: Pallet;
    changeProductPrice: (e: any, id: any, type: string) => void;
    priceInputId: string;
    setSellingPriceState: (e: any, productId: any, selling_price: number) => void;
    priceInputValue: number;
    setPriceInputValue: (value: number) => void;
    current_package_id?: number;
}
const handleOpenNewTab = (pallet: Pallet, url: string) => {
    // Open the link in a new tab using window.open
    const newTab: any = window.open(url, '_blank');
    const serializedProduct = JSON.stringify(pallet);
    newTab.localStorage.setItem('pallet', serializedProduct);
};
const WarehousePalletCardComponent = (props: PalletCardComponentProps) => {
    const { cardOnClick, selectedPallets, item, priceInputId, setSellingPriceState,
        changeProductPrice, priceInputValue, setPriceInputValue, current_package_id } = props;
    const [buttonLoader, setButtonLoader] = useState<boolean>(false);
    
    return (
        <CCard // Parent card component.
            className = {selectedPallets?.includes(item.id) ? "selected-card" : 'unselected-card'}
        >
            <CCardBody>
                <CCardTitle>Description: {item.description}</CCardTitle>
                <h6>
                    <CCardText className='product-card-info'>
                        <div
                            style={{
                                cursor: 'pointer',
                                textDecoration: 'underline'
                            }}
                            onClick={(e)=>{
                                e.stopPropagation()
                                handleOpenNewTab(item, '/admin/dashboard/pallet/details')
                            }}
                        ><b>Pallet id: {item.id}</b></div>
                        <div><b>Products inside: {item?.products_inside}</b></div>
                        <div><b>Buying price: {item?.buying_price} €</b></div>
                        
                        { item?.supply ? <div><b>Supply id: {item.supply}</b></div> : null}
                        
                        <div><b>Online price: {item?.is_sold ? item.selling_online_price : item?.current_online_price} €</b></div>
                        <div><b>Remarks: {item?.metadata?.remarks}</b></div>
                        <Divider color={ selectedPallets?.includes(item.id) ? '#ffffff' : "#23B2D9"} thickness={1} margin={12} />
                        <h5>
                            {
                                priceInputId.includes(String(item.id)) ?
                                    <div className="input-wrapper margin-top-12">
                                        <b>Selling price:</b>
                                        <div className="input-container">
                                            <input type={"number"} onClick={(e)=>{e.stopPropagation()}} id={`selling-price-input-${item.id}`} defaultValue={item.selling_price} value={priceInputValue} onChange={(e) => setPriceInputValue(Number(e.target.value))} />
                                            <CIcon size='xl' icon={cilCheck} onClick={(e) => {priceInputValue == 0 ? alert(" Selling price cannot be 0.") : changeProductPrice(e, item.id, 'pallet')}}/>
                                        </div>
                                    
                                    </div>
                                :
                                    <div className="margin-top-12"><b>Selling price: {  Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(item.selling_price) } € </b>  { (!item.is_sold || item.package_id == current_package_id) ?<CIcon icon={cilPen} size='lg' onClick={(e) => setSellingPriceState(e, item.id, item.selling_price)}/> : null }</div>
                            }
                        </h5>                        
                        <div>
                            {item?.is_sold && item.package_id != current_package_id && (
                                <>
                                    <div style={{color: 'red'}}>{item?.order_id ? <b>SOLD</b> : <b>OFFERED</b> }</div>
                                    <div style={{ marginTop: '5px' }}>
                                        <CBadge color="dark">
                                        <b>{item?.customer_name}</b>
                                        </CBadge>
                                    </div>
                                    <div style={{ marginTop: '5px' }}>
                                        <CBadge color="info">
                                        <b>PackageID: {item?.package_id}</b>
                                        </CBadge>
                                    </div>
                                    {item?.order_id && (
                                        <div style={{ marginTop: '5px' }}>
                                        <CBadge color="warning">
                                            <b>OrderID: {item?.order_id}</b>
                                        </CBadge>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </CCardText>
                </h6>
                {
                    (!item.is_sold || item.package_id == current_package_id) ?
                    <CButton
                        color='link'
                        variant="outline"
                        shape="square"
                        disabled={buttonLoader}
                        style={{color: selectedPallets.includes(item.id) ? '#23B2D9': 'white', backgroundColor: selectedPallets.includes(item.id) ? 'white': '#23B2D9'}}
                        onClick={() => {
                            setButtonLoader(true);
                            cardOnClick(()=>setButtonLoader(false))
                        }}
                    >
                        {buttonLoader ? <CSpinner /> : selectedPallets.includes(item.id) ? "Remove from cart" : "Add to cart"}
                    </CButton>
                    : null
                }
            </CCardBody>
        </CCard>
    )
};

export default WarehousePalletCardComponent;