

import { useState } from 'react';
import 'simplebar/dist/simplebar.min.css';
import { CButton,  CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CSmartTable } from '@coreui/react-pro';
import {createPackage, deletePackage, getPackageSnapshots, getTruckCapacity, modifyPackage, revertPackageSnapshot} from '../../../services/PackageService';

import '../../AdminStyles.css';
import { CreatPackageObject, DropDownItem, Package } from '../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux/store';
import { setPendingPackages } from '../../../redux/slices/PackageSlice';
import { ADMIN, PACKAGE_STATUS, SALES_ASSISTANT_GROUP_ID } from '../../../constants';
import { t } from 'i18next';
import _ from 'lodash'; // Import lodash library
import SnapshotModal from '../../components/modals/SnapshotModal';
import { placeOrder } from '../../../services/OrderService';
import { downloadExcelFile } from '../../components/GenerateExcelFile';
import { CBadge, CCol, CFormLabel, CRow, CSpinner, CTooltip } from '@coreui/react';
import GenericConfirmationModal from '../../components/modals/DeleteItemModal';
import { Utils } from '../../components/utils';
import { getOrderPDFURL } from '../../components/DownloadPDF';
import Select, {components} from 'react-select';

const WarehouseCartScreen = () => {
    const dispatch = useDispatch();
    const navigation = useNavigate();  
    const { getPackagesData, getOrdersData, PackagesColumns, getPendingPackagesData, returnDateFormat } = Utils();
    const authUser = useSelector((state: RootState) => state.authSlice.user);
    const pendingPackages: Package[] = useSelector((state: RootState) => state.packageSlice.pendingPackages);
    const IS_ADMIN_OR_SUPERADMIN = (authUser.is_superuser || authUser?.groups?.includes(ADMIN));
    const customers = useSelector((state: RootState) => state.usersSlice.customers);
    const customerOptions: DropDownItem[] = customers?.map((customer) => ({ value: customer.id, label: customer.company }));
    const [selectedCustomer, setSelectedCustomer] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const [state, setState] = useState<any>({
        activePageNumber: 1,
        itemsPerPage: 20,
        loading: false,
        details: [],
        userInteracted: false,
        loadMore: false,
        isModalVisible: false,
        orderConfirmationModal: false,
        deletePackageConfirmationModal: false,
        totalProducts: 0,
        errorText: '',
    });  

    const deletePackages = (id: number) => {
        setState({
            ...state,
            loading: true,
        });
        let updatedPackages = pendingPackages?.filter((packageItem: Package) => {
            return (packageItem.id !== id)
        });
        dispatch(setPendingPackages([...updatedPackages]));

        setState({
            ...state,
            loading: false,
            deletePackageConfirmationModal: false,
        });
        deletePackage(id).then((response) => {
            getOrdersData();
            getPackagesData();
        }).catch((e) => {
        setState({
            ...state,
            loading: false,
            deletePackageConfirmationModal: false,
        });
        getPackagesData();
        getPendingPackagesData();
        getOrdersData();
        console.log('delete package error: ', e);
        })
    };
   

    const confirm = (packageItem: Package) => {   
        let products = packageItem?.products?.map(number => `'${number}'`).join(',');

        let form_data = {
            "lager_numbers": '(' +  products + ')',
            fetched_pallet_count: packageItem?.pallets_products?.length ? packageItem?.pallets_products?.length : 0,
        }
        getTruckCapacity(form_data).then((response) => {
            const totalCapacityOccupied = Number(response?.data?.truck_details?.occupancy["total_occupied_in_meters"]);
            if(!totalCapacityOccupied ) {
            confirmOrder(packageItem, 0);
            }
            else {
            confirmOrder(packageItem, totalCapacityOccupied);
            }
        }).catch((e)=> {
            console.log('Truck capacity Exception: ', e);
            confirmOrder(packageItem, 0);
        });
    }

    const confirmOrder = (packageItem: Package, totalCapacityOccupied: number) => {
        let formData =  {
            "products": packageItem.products,
        }
        setState({
            ...state,
            loading: true,
            orderConfirmationModal: true,
        })
        modifyPackage(packageItem.id, formData).then((response) => {
            const itemSelected = (packageItem.quantity / state.totalProducts) * 100;
            let form_data =  {
              package: packageItem.id,
              truck_capacity: totalCapacityOccupied,
              items_selected: itemSelected.toFixed(2),
            };
            placeOrder(form_data).then((response) => {
              getOrderPDFURL(response.data.id);
              getPackagesData();
              getPendingPackagesData();
              navigation('/admin/dashboard/orders/requests', {state: {reload: true}});
            }).catch((e: any) =>{
              console.log('error while placing order: ', e);
              setState({
                  ...state,
                  loading: false,
                  orderConfirmationModal: false,
              })
            })
        }).catch((e: any) =>{
            console.log('error while modifying package: ', e);
        })
    }

    const calculateItemsSelected = (packageItem: Package) => {
        getPackageSnapshots(packageItem.id).then((response) => {
            setState({
            ...state,
            selectedPackageItem: packageItem,
            orderConfirmationModal: true,
            totalProducts:  response?.data[0]?.products.length ? response?.data[0]?.products.length : packageItem.quantity ,
            })
        }).catch((e) => {
            setState({
            ...state,
            selectedPackageItem: packageItem,
            orderConfirmationModal: true,
            totalProducts: packageItem.quantity,
            })
        });
    }

    const CreatePackage = () => {
        let packageObject: CreatPackageObject =  {
            products: [],
            discount: 0,
            reminders_count: 2,
            reminder_timedelta_days: "27 00:00:00",
            package_validity_hours: "30 00:00:00",
            customer: selectedCustomer?.value || null,
            is_warehouse_package: true,
        }
        createPackage(packageObject).then((response) => {
            getPackagesData();
            getPendingPackagesData();
            setLoading(false);
            navigation('/admin/dashboard/create/warehouse-package',{state: {packageItem: response?.data?.id}});
        }).catch((e: any) => {
        });
    }

  return (
    <div className='website-settings-main-container'>
      <GenericConfirmationModal
        loading={state.loading}
        isModalVisible={state.orderConfirmationModal}
        closeModal={()=> setState({...state, orderConfirmationModal: false})}
        headerText={`${t('adminDashboard.Packages.are_you_sure')}`}
        bodyText={`${t('adminDashboard.PendingPackages.place_order_confirmation')}`}
        confirmButtonText={`${t('adminDashboard.Packages.place_order')}`}
        onClickConfirmation={() => {          
          confirm(state.selectedPackageItem)
        }}
      />
      <GenericConfirmationModal
        isModalVisible={state.deletePackageConfirmationModal}
        closeModal={()=> setState({...state, deletePackageConfirmationModal: false})}
        headerText={`${t('adminDashboard.Packages.are_you_sure')}`}
        bodyText={`${t('adminDashboard.SupplyInventory.confirm_delete_item')}`}
        confirmButtonText={`${t('adminDashboard.CommonWords.delete')}`}
        onClickConfirmation={() => {
          deletePackages(state.selectedPackageItem.id);
        }}
      />
      <h1><b>{t('adminDashboard.Warehouse.sidebar_text')}</b></h1>
        <CRow className='margin-top-16 margin-bottom-44'>
            <CCol xs={6} md={4}>
                <CFormLabel><b>{t('adminDashboard.Packages.customer_text')}*</b></CFormLabel>
                {
                    <Select
                        value={selectedCustomer}
                        placeholder={t('adminDashboard.CommonWords.select')}
                        options={customerOptions}
                        onChange={(option) => setSelectedCustomer({value: option?.value, label: option?.label})}
                    />
                }
            </CCol>
            <CCol xs={6} md={4}>
                <CButton
                  disabled={loading}
                  className='margin-top-24'
                  onClick={() => {setLoading(true); CreatePackage()}}
                >{loading ? <CSpinner size='sm'/> : t('adminDashboard.OrderRequests.create_cart')}</CButton>
            </CCol>
        </CRow>
       
        <h1 className='margin-bottom-36'><b>{t('adminDashboard.OrderRequests.customer_carts')}</b></h1>
        <CSmartTable
          columns={PackagesColumns}
          tableFilter
          tableFilterLabel={`${t('adminDashboard.CommonWords.search')}`}
          tableFilterPlaceholder={`${t('adminDashboard.CommonWords.search')}`}
          items={pendingPackages?.filter((item) => item.is_warehouse_package)}
          itemsPerPageSelect
          itemsPerPageLabel={`${t('adminDashboard.CommonWords.items_per_page')}`}
          noItemsLabel={`${t('adminDashboard.CommonWords.no_items_found')}`}
          loading={state.loading}
          tableHeadProps={{
            color: 'info',
          }}
          tableProps={{
            hover: true,
            responsive: true,
            striped: true,            
          }}          

          tableBodyProps={{
            className: 'align-middle'
          }}

          scopedColumns={{
            id: (item: Package) => {
              return (
                <td className="text-center">
                  {item.id}
                </td>
              )
            },
            creator_first_name: (item: Package) => {
              return (
                <td className="text-center">
                  {item.creator_first_name}
                </td>
              )
            },
            customer_company: (item: Package) => {
              return (
                <td className="text-center">
                  {item.customer_company}
                </td>
              )
            },
            created_at: (item: Package) => {
              return (
                <td className="text-center">
                  {returnDateFormat(item.created_at)}
                </td>
              )
            },
            status: (item: any) => {
              return (
                <td className='text-center'>
                  <CBadge color={item.status == 'A' ? 'warning' : item.status == 'M' ? 'info' : item.status == 'F' ? 'success' : 'light'}>
                    <b>
                      {item.status && item.status == 'A' ? t('adminDashboard.CommonWords.pending') 
                        : item.status && item.status == 'M' ? t('adminDashboard.CommonWords.order_requested') 
                        : item.status && item.status == 'F' ? t('adminDashboard.OrdersWords.ordered') 
                        : null
                      }
                    </b>
                  </CBadge>
                  <div>
                    {
                      item.is_warehouse_package ? <CBadge className='margin-top-12' color={'dark'}><b>{t('adminDashboard.Warehouse.sidebar_text') }</b></CBadge> : null
                    }
                  </div>
                </td>
              )
            },
            sub_total: (item: any) => {
              return (
                <td className='text-center'>
                   {Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(item.sub_total)}                 
                </td>
              )
            },
            discount: (item: any) => {
              return (
                <td className='text-center'>
                  {item.discount}
                </td>
              )
            },
            total: (item: any) => {
              return (
                <td className='text-center'>
                  {Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(item.total)}
                </td>
              )
            },
            quantity: (item: any) => {
              return (
                <td className='text-center'>
                  {item.quantity}
                </td>
              )
            },
            creator_name: (item: any) => {
              return (
                <td className='text-center'>
                  {item.creator_name}
                </td>
              )
            },
            supplies: (item: any) => {
              const visibleSupplies = item.supplies.slice(0, 3);
              const tooltipSupplies = item.supplies.slice(3);
              return (
                <td>
                  <CTooltip
                    content={tooltipSupplies.length > 0 ? tooltipSupplies.join(', ') : 'No more supplies'}
                    placement="top"
                  >
                    <div className="text-center" style={{ maxWidth: 220, wordWrap: 'break-word' }}>
                      {visibleSupplies.join(', ')}
                    </div>
                  </CTooltip>
                </td>
              );
            },
            note: (item: any) => {
              return (
                <td className='text-center' style={{maxWidth: 220, wordWrap: 'break-word'}}>
                  {item?.note || "--"}
                </td>
              )
            },
            show_details: (item: any) => {
              return (
                <td>
                  <CDropdown>
                    <CDropdownToggle className="elv-btn" href="#" style={{width: 110}}>{t('adminDashboard.CommonWords.action')}</CDropdownToggle>
                    <CDropdownMenu style={{position: 'absolute', right: 20}}>
                        <CDropdownItem  onClick={() =>  {downloadExcelFile(item.id, true)}}>{t('adminDashboard.CommonWords.download_excel')}</CDropdownItem>
                        <CDropdownItem  onClick={() =>  { navigation('/admin/dashboard/packages/details', {state: {packageItem: item}})}}>{t('adminDashboard.CommonWords.view')}</CDropdownItem>
                          {item?.customer_company && (((IS_ADMIN_OR_SUPERADMIN || authUser?.groups?.includes(SALES_ASSISTANT_GROUP_ID)) && item.status != 'F') || ((item.status != 'F') && (item.creator_id == authUser.id))) ? <CDropdownItem  onClick={() =>  {calculateItemsSelected(item)}}>{t('adminDashboard.CommonWords.place_order')}</CDropdownItem> :  null}
                          {(IS_ADMIN_OR_SUPERADMIN || authUser?.groups?.includes(SALES_ASSISTANT_GROUP_ID)) || (item.creator_id == authUser.id) ? 
                          <CDropdownItem 
                            onClick={() => {navigation('/admin/dashboard/create/warehouse-package', {state: {packageItem: item.id}})}}>
                            {t('adminDashboard.CommonWords.edit')}
                          </CDropdownItem> : null}
                          {IS_ADMIN_OR_SUPERADMIN || authUser?.groups?.includes(SALES_ASSISTANT_GROUP_ID) || (item.creator_id == authUser.id) ? <CDropdownItem  onClick={() =>  {
                          setState({
                            ...state,
                            selectedPackageItem: item,
                            deletePackageConfirmationModal: true,
                          })
                          }}>{t('adminDashboard.CommonWords.delete')}</CDropdownItem> : null}
                    </CDropdownMenu>
                  </CDropdown>
                </td>
              )
            }
          }}
        />
    </div>
  );
};
export default WarehouseCartScreen;
