

import { useEffect, useState } from 'react'
import 'simplebar/dist/simplebar.min.css';
import '../../AdminStyles.css';
import { DropDownItem, LoggedinUser, Package, ProductDetailedSetArticle, ProductItem, Usage } from '../../../types';
import ProductDetailsCardComponent from '../../../customer/components/ProductDetailsCardComponent';
import { getProductById } from '../../../services/ProductService';
import { CCol, CRow, CSpinner } from '@coreui/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { ADMIN, BACK_OFFICE_GROUP_ID, SALES_ASSISTANT_GROUP_ID, SUPER_ADMIN } from '../../../constants';
import { Utils } from '../../components/utils';

const ProductDetailScreen = () => {
  const {getUsageData, getAllProductCategoryQuestions, getAllProductArticles, getAllProductSubCategories} = Utils();
  const authUser: LoggedinUser = useSelector((state: RootState) => state.authSlice.user);
  const productDataString = localStorage.getItem('product');
  const productObject: ProductDetailedSetArticle = productDataString ? JSON.parse(productDataString) : null;
  const [combinedProduct, setCombinedProduct] = useState<ProductDetailedSetArticle>();
  const [product, setProduct] = useState<ProductDetailedSetArticle | ProductItem>();
  const [loading, setLoading] = useState<boolean>(true);

  const packageItemString = localStorage.getItem('packageItem');
  const packageItem: Package = packageItemString ? JSON.parse(packageItemString) : null;
  
  useEffect(()=>{ 
    packageItem?.set_articles.map((item: ProductDetailedSetArticle) => {
      if(productObject?.combined_products?.length > 0 && item.id === productObject?.combined_products[0] && item.id !== productObject.id) {
        getProductById(item.id).then((response) => {
          setCombinedProduct(response.data);
          setLoading(false);
        }).catch((e) => {
          console.log('get products error: ', e);
          setLoading(false);
        });        
      }
    });
    getUsageData();
    getAllProductSubCategories();
    getAllProductCategoryQuestions();
    getAllProductArticles();
    getProductById(productObject.id).then((response) => {
      setProduct(response.data);
      setLoading(false);
    }).catch((e) => {
      console.log('get products error: ', e);
      setLoading(false);
    });
  },[]);
  
  const images = product?.image_list?.map((item: any) => {
    return {
      question_number: item,
      url:  `https://mmlpy45aff.execute-api.eu-central-1.amazonaws.com/image?lager=${product.id}&filename=${item}.jpg`
    }
  }) 

  const combinedProductImages = combinedProduct && combinedProduct.image_list?.map((item: any) => {
    return {
      question_number: item,
      url:  `https://mmlpy45aff.execute-api.eu-central-1.amazonaws.com/image?lager=${combinedProduct.id}&filename=${item}.jpg`
    }
  });

  return (
    <div>
      <div className='website-settings-main-container'>
        <h3><b>Product details</b></h3>
        {
          loading ?
            <CRow><CCol className="text-center"><CSpinner /></CCol></CRow> 
            :
            <>
              {product &&
                <div>
                  <ProductDetailsCardComponent
                    productEditable = {combinedProduct ? false : (authUser.groups?.includes(BACK_OFFICE_GROUP_ID) || authUser.groups?.includes(SUPER_ADMIN) ||  authUser.groups?.includes(SALES_ASSISTANT_GROUP_ID) || authUser.groups?.includes(ADMIN)) ?  true : false}
                    images={images}
                    product={ product && product }
                    showButton={false}
                    setProduct={setProduct}
                  />
                </div>
              }
              {
                combinedProduct &&
                <div className='margin-top-24'>
                  <ProductDetailsCardComponent
                    productEditable={false}
                    images={combinedProductImages}
                    product={combinedProduct}
                    showButton={false}
                    setProduct={setProduct}
                  />
                </div>
              }
            </>
        }
      </div>
    </div>
  );
};
export default ProductDetailScreen;

