import { CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CButton, CModalContent, CSpinner, CRow, CCol } from "@coreui/react";
import { useState } from "react";

interface ModalProps {
    isModalVisible: boolean;
    headerText: string;
    bodyText: string;
    closeModal: () => void;
    onClickConfirmation: () => void;
    confirmOrder?: () => void;
    confirmButtonText: string;
}
const ConfirmationModal = (props: ModalProps) => {
    const {isModalVisible, closeModal, headerText, onClickConfirmation, bodyText, confirmButtonText, confirmOrder} = props;
    const [loading, setLoading] = useState<boolean>(false);
  
    return (
        <CModal size="lg" visible={isModalVisible} onClose={closeModal}>
            <CModalHeader className="modal-header">
                <CModalTitle>{headerText && headerText}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div>{loading ? <CRow><CCol className="text-center"><CSpinner /></CCol></CRow> : bodyText}</div>
            </CModalBody>
            <CModalFooter>
                <CButton color="primary" onClick={() => {setLoading(true); onClickConfirmation()}}>{confirmButtonText}</CButton>
                <CButton color="primary" onClick={() => {setLoading(true); confirmOrder && confirmOrder() }}>Accept order</CButton>
                <CButton color="primary" onClick={closeModal}>Cancel</CButton>
            </CModalFooter>
        </CModal>
    );
}
export default ConfirmationModal;