import axios from "axios";
import {BACKEND_API_URL, CRM_API_KEY} from "../constants";
import { AuthUser } from "../types";

const api = axios.create({
    baseURL: BACKEND_API_URL, 
    withCredentials: true,
    headers: {
        //'accept': 'application/json',
        'Content-Type': 'application/json',
    }
  });
  const CRM_API = axios.create({
    baseURL: 'https://elvincidegmbh-org.myfreshworks.com/crm/sales/api/', 
    withCredentials: true,
    headers: {
        'Authorization':`Token token=${CRM_API_KEY}`,
        'Content-Type': 'application/json',
        //'Access-Control-Allow-Origin': 'https://example.com:3000',
    }
  });

export const loginUser = async(email: string, password: string) => {
    return await api.post(
        'auth/login/',
        {
            "email": email,
            "password": password,
        },
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};
export const logoutUser = async() => {
    return await api.post(
        'auth/logout/',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const registerUser = async(userObject: AuthUser) => {
    return await api.post(
        'auth/registration/',
        userObject,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const changeAuthPassword = async(formData: any) => {
    return await api.post(
        'auth/password/change/',
        formData,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};


export const getPendingCustomers = async() => {
    return await api.get(
        'api/users/pending/',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};
export const toggleCustomerStatus = async(id: number) => {
    return await api.patch(
        `api/users/pending/${id}/toggle-accepted/`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const updateAuthUser = async(formData: any) => {
    return await api.patch(
        `auth/user/`,
        formData
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getAuthUser = async() => {
    return await api.get(
        'auth/user/',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getAddress = async() => {
    return await api.get(
        'api/address/',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getRefreshToken = async() => {
    return await api.post(
        'auth/token/refresh/',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const assignPermissions = async(id: number, formData: any) => {
    return await api.patch(
        `api/groups/${id}/`,
        formData,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
}

export const getAllPermissions = async() => {
    return await api.get(
        `api/permissions/`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
}

export const getCRMAppointments = async() => {
    return await CRM_API.get(
        `appointments/`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};
export const getCRMMarketingLists = async() => {
    return await CRM_API.get(
        `lists/`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};


