import axios from "axios";
import {BACKEND_API_URL} from "../constants";
import { ExcelDataObject, SupplyTypePost, filterRequestObject } from "../types";

const api = axios.create({
    baseURL: BACKEND_API_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
  });

const uploadApi = axios.create({
    baseURL: BACKEND_API_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});


export const filterPallets = async(form_data = {}) => {
    // form data is empty for now but we can edit as per requirements in the future.
    return await api.post(
        `api/pallets/query/`,
        form_data,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const editPalletDetails = async(id: any, form_data: any) => {
    return await api.patch(
        `api/pallets/${id}/`,
        form_data,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const editPalletProduct = async(id: any, form_data: any) => {
    return await api.patch(
        `api/pallet-products/${id}/`,
        form_data,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};
export const addPalletProduct = async(form_data: any) => {
    return await api.post(
        `api/pallet-products/`,
        form_data,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const deletePalletProduct = async(id: any) => {
    return await api.delete(
        `api/pallet-products/${id}/`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getPallets = async() => {
    return await api.get(
        `api/pallets/`
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getPalletById = async(id: number | string) => {
    return await api.get(
        `api/pallets/${id}/`
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getActivePalletsCount = async() => {
    return await api.get(
        `api/pallets/active-pallets-count/`
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
}

export const getFilteredPalletProducts = async(pallet_id: any) => {
    return await api.get(
        `api/pallet-products/filter-pallet-products/?pallet_id=${pallet_id}`
       
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const uploadPalletManually = async(formData: any) => {
    return await uploadApi.post(
        `api/upload-bulk-pallets/upload-bulk-pallets/`,
        formData,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getPalletsBySupplyId = async(filtersData: filterRequestObject) => {
    let supplyIds = filtersData.supply_id;
    let fullUrl = `api/pallets/query/?`;
    if (supplyIds && supplyIds.length > 0) {
        const queryParams = supplyIds.map(id => `supply=${encodeURIComponent(id)}`).join('&');
        fullUrl += `${queryParams}`;
    }
    try {
        const response = await api.post(fullUrl);
        return response;
    } catch (error) {
        throw error;
    }
};