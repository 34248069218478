import { Form } from "react-bootstrap";
import './Authstyles.css';
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import BlackLogo from '../assets/images/black-logo.png'
import { registerUser } from "../services/AuthService";
import { CHeader } from "@coreui/react";
import RegistrationForm from "./RegistrationForm";
import { AuthUser } from "../types";

function RegistrationScreen() {
  const { t, i18n } = useTranslation();
  const navigation = useNavigate();
  const [registrationSuccessful, setRegistrationSuccessful] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("")
  const signup = (authUser: AuthUser, callBack: () => void) => {
    registerUser(authUser).then((response) => {
      setErrorMessage('');
      callBack();
      setRegistrationSuccessful(true);
    }).catch((e) => {
      console.log('register user error: ', e);
      callBack();
      if(e?.response?.data?.password1) {
        setErrorMessage(e?.response?.data?.password1);
    }
    });
  }
  useEffect(()=>{
  },[])

  return (
    <div className="main-container">
      <div className="bg-image-blurred"></div>
      <div className="signup-form-container col-md-4 col-lg-4 col-sm-8">
        <div style={{textAlign: "center"}}>
            <img src={BlackLogo} style={{width: 170}}/>
        </div>
        {/* <div style={{textAlign: "center", marginTop: 14, marginBottom: 14}}>
            <h6>{t('authentication.register.info')}</h6>
        </div> */}
        {
          registrationSuccessful ?
          <>
              <CHeader><h4><b>Registration Successful Please wait while our support approves your account.</b></h4></CHeader>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Link to="/login">Login?</Link>
              </Form.Group></>
          :
          <RegistrationForm
            signup={signup}
            errorMessage={errorMessage}
          />
        }
      </div>
    </div>
  );
}

export default RegistrationScreen;
