import { useState } from 'react'
import 'simplebar/dist/simplebar.min.css';
import '../../AdminStyles.css';
import { CButton, CFormInput, CForm, CHeader, CFormLabel, CSpinner, CToaster, CCol, CRow} from '@coreui/react';
import { useLocation } from 'react-router-dom';
import { updateCustomer } from '../../../services/CustomerService';
import { registerUser } from '../../../services/AuthService';
import Select from 'react-select';

import {UserCreatedSuccessToast, UserEditedSuccessToast, UserEditedErrorToast, GenericErrorToast} from '../../components/create-package-components/ToastsComponent';
import { Utils } from '../../components/utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { Country, DropDownItem } from '../../../types';
import { useTranslation } from 'react-i18next';

const AddUserScreen = () => {
    const state = useLocation();
    const {t} = useTranslation();
    const {getCustomersData} = Utils();
    const [toast, addToast] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const countriesList: Country[] = useSelector((state: RootState) => state.usersSlice.countries);
    const countriesOptions: DropDownItem[] = countriesList.map((country: Country) => ({ value: country.id, label: country.name, is_eu: country.is_eu }));
    
    const [email, setEmail] = useState<string>(state?.state?.customerObject?.email);
    const [password, setPassword] = useState<string>(state?.state?.customerObject?.user?.password);
    const [firstName, setFirstName] = useState<string>(state?.state?.customerObject?.first_name);
    const [lastName, setLastName] = useState<string>(state?.state?.customerObject?.last_name);
    const [phoneNumber, setPhoneNumber] = useState<string>(state?.state?.customerObject?.phone_number || '');
    const [company, setComapany] = useState<string>(state?.state?.customerObject?.company);
    const [jtlID, setjtlID] = useState<number>(state?.state?.customerObject?.jtl_id);

    const [address, setAddress] = useState<string>(state?.state?.customerObject?.shipping_address?.address);
    const [country, setCountry] = useState<DropDownItem>(countriesOptions?.length > 0 ? countriesOptions?.filter((country) => { 
        return country.label == state?.state?.customerObject?.shipping_address?.country
    })[0] : countriesOptions[0]);
    const [zipcode, setZipcode] = useState<string>(state?.state?.customerObject?.shipping_address?.zipcode);
    const [city, setCity] = useState<string>(state?.state?.customerObject?.shipping_address?.city);

    const [errorMessage, setErrorMessage] = useState<string>("");
    const isDataValid = () : boolean => {
        if (state?.state?.editText) {
            return email?.length > 0 && email?.includes('@') && firstName?.length > 0 &&
            lastName?.length > 0 && company?.length > 0 && address?.length > 0 && country?.label?.length > 0 &&
            zipcode?.length > 0 && city?.length > 0 && jtlID > 0
        }
        else {
            return email?.length > 0 && email?.includes('@') && password?.length > 0 && firstName?.length > 0 &&
            lastName?.length > 0 && phoneNumber?.length > 0 && company?.length > 0 && address?.length > 0 && country && country?.label?.length > 0 &&
            zipcode?.length > 0 && city?.length > 0 && jtlID > 0
        }
    }
    const addCustomerData = () => {
        if(state?.state?.editText) {
            setIsLoading(true);
            updateCustomer(state.state.customerObject?.id, {
                user: {                   
                    first_name: firstName,
                    last_name: lastName,
                    phone_number: phoneNumber,
                },
                company,
                jtl_id: jtlID,
                shipping_address: { address, country: country?.label, zipcode, city },
                billing_address: { address, country: country?.label, zipcode, city },
                use_same_address: true,
            }).then((response) => {
                setIsLoading(false);
                getCustomersData();
                addToast(UserEditedSuccessToast(()=>{
                    addToast(null);
                }));
            }).catch((e) => {               
                setIsLoading(false);
                addToast(UserEditedErrorToast(()=>{
                    addToast(null);                  
                }));
            });
        }
        else {
            setIsLoading(true);
            registerUser({
                email,
                password1: password,
                password2: password,
                jtl_id: jtlID,
                first_name: firstName,
                last_name: lastName,
                phone_number: phoneNumber,
                company,
                reference: 'Elvinci Personal',
                shipping_address: { address, country: country?.label, zipcode, city },               
                use_same_address: true,
            }).then((response) => {
                setIsLoading(false);
                getCustomersData();
                addToast(UserCreatedSuccessToast(()=>{
                    addToast(null);
                }));
                setErrorMessage('');
            }).catch((e) => {
                setIsLoading(false);
                if(e?.response?.data?.password1) {
                    setErrorMessage(e?.response?.data?.password1);
                    addToast(GenericErrorToast(()=>{
                        addToast(null);
                    }, `${e?.response?.data?.password1}`));  
                }
            });
        }
    }
    return (
        <div className='website-settings-main-container'>
            <CToaster push={toast} placement="top-end" />
            <CHeader><h2><b>{state?.state?.editText? state.state.editText :  "Add New User"}</b></h2></CHeader>
            <CForm style={{marginBottom: 16, marginTop: 24}}>
            <CRow>
                <CCol xs={7} className='padding-top-16'>
                    <CFormLabel><b>Email*</b></CFormLabel>
                    <CFormInput
                        type={"email"}
                        placeholder='Enter Email'
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        style={{marginBottom: 16}}
                        disabled={state?.state?.editText ? true : false}
                    />
                </CCol>
            </CRow>

            <CRow>
                <CCol xs={7} className='padding-top-16'>
                    {
                        !state?.state?.editText? <CFormLabel><b>Password*</b></CFormLabel> : null
                    }
                    {
                        !state?.state?.editText? 
                            <CFormInput
                                type={"password"}
                                placeholder='Enter User Password'
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                                style={{marginBottom: 16}}
                            />
                        : null
                    } 
                </CCol>
            </CRow>

            <CRow>
                <CCol xs={7} className='padding-top-16'>
                    <CFormLabel><b>JTL ID*</b></CFormLabel>
                    <CFormInput
                        placeholder='Enter User JTL ID'
                        type={'number'}
                        value={jtlID}
                        onChange={(e) => {
                            setjtlID(Number(e.target.value));
                        }}
                        style={{marginBottom: 16}}
                        required
                    />
                </CCol>
            </CRow>

            <CRow>
                <CCol xs={7} className='padding-top-16'>
                    <CFormLabel><b>First Name*</b></CFormLabel>
                    <CFormInput
                        placeholder='Enter First Name'
                        value={firstName}
                        onChange={(e) => {
                            setFirstName(e.target.value);
                        }}
                        style={{marginBottom: 16}}
                    />
                </CCol>
            </CRow>

            <CRow>
                <CCol xs={7} className='padding-top-16'>
                    <CFormLabel><b>Last Name*</b></CFormLabel>
                    <CFormInput
                        placeholder='Enter Last Name'
                        value={lastName}
                        onChange={(e) => {
                            setLastName(e.target.value);
                        }}
                        style={{marginBottom: 16}}
                    /> 
                </CCol>
            </CRow>

            <CRow>
                <CCol xs={7} className='padding-top-16'>
                    <CFormLabel><b>Company*</b></CFormLabel>
                    <CFormInput
                        placeholder='Enter Company'
                        value={company}
                        onChange={(e) => {
                            setComapany(e.target.value);
                        }}
                        style={{marginBottom: 16}}
                    />  
                </CCol>
            </CRow>

            <CRow>
                <CCol xs={7} className='padding-top-16'>
                    <CFormLabel><b>Phone Number*</b></CFormLabel>
                    <CFormInput
                        placeholder='Enter Phone Number'
                        value={phoneNumber}
                        onChange={(e) => {
                            setPhoneNumber(e.target.value);
                        }}
                        style={{marginBottom: 16}}
                    />
                </CCol>
            </CRow>

            <CRow>
                <CCol xs={7} className='padding-top-16'>
                    <CFormLabel><b>Country*</b></CFormLabel>
                    <Select
                        value={country}
                        placeholder={`${t('adminDashboard.CommonWords.select')}`}
                        noOptionsMessage={({ inputValue: string }) => 'Country not found.' }
                        options={countriesOptions}
                        onChange={(option: any) => {
                            console.log(option)
                            setCountry(option)
                        }}
                    />
                </CCol>
            </CRow>

            <CRow>
                <CCol xs={7} className='padding-top-16'>
                    <CFormLabel><b>Zip code*</b></CFormLabel>
                    <CFormInput
                        placeholder='Enter Zip code'
                        value={zipcode}
                        onChange={(e) => {
                            setZipcode(e.target.value);
                        }}
                        style={{marginBottom: 16}}
                    />
                </CCol>
            </CRow>

            <CRow>
                <CCol xs={7} className='padding-top-16'>
                    <CFormLabel><b>City*</b></CFormLabel>
                    <CFormInput
                        placeholder='Enter City'
                        value={city}
                        onChange={(e) => {
                            setCity(e.target.value);
                        }}
                        style={{marginBottom: 16}}
                    /> 
                </CCol>
            </CRow>

            
            <CRow>
                <CCol xs={7} className='padding-top-16'>
                    <CFormLabel><b>Address*</b></CFormLabel>
                    <CFormInput
                        placeholder='Enter Address'
                        value={address}
                        onChange={(e) => {
                            setAddress(e.target.value);
                        }}
                        style={{marginBottom: 16}}
                    /> 
                </CCol>
            </CRow>
            
            <CRow>
                <CCol xs={7} className='padding-top-16'>
                    <CButton
                        disabled={!isDataValid()}
                        onClick={addCustomerData}>
                        {isLoading? <CSpinner /> : state?.state?.buttonText? state.state.buttonText : "Add"}
                    </CButton>
                </CCol>
            </CRow>
            {
                errorMessage && errorMessage.length > 0 ?
                    <p style={{color: 'red', marginTop: 8}}>Error! {errorMessage}</p>
                :    null
            }
            </CForm>
        </div>
    )
};
export default AddUserScreen;
