import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AuthUser, Country } from "../types";
import { Utils } from "../admin/components/utils";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useTranslation } from "react-i18next";
import { CFormFeedback, CSpinner } from "@coreui/react";

interface RegistrationFormProps {
    signup: (authUser: AuthUser, callBack: () => void) => void;
    errorMessage?: string;
}
function RegistrationForm(props: RegistrationFormProps) {
  const {errorMessage} = props;
    const {getCountries} = Utils();
    const {t} = useTranslation();
    const countriesList: Country[] = useSelector((state: RootState) => state.usersSlice.countries);
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
      getCountries();
    },[])

    useEffect(() => {
      if (countriesList?.length > 0) {
        setCountry(countriesList[0]?.name);
      }
    },[countriesList]);
    
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [companyName, setCompanyName] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [zipcode, setZipcode] = useState<string>("");
    const [country, setCountry] = useState<string>(countriesList?.length > 0 ? countriesList[0].name : "");
    const [findUs, setFindUs] = useState<string>("");
    const isDataValid = () : boolean => {
      return (email?.length > 0 && email?.includes('@') && password?.length > 0 && firstName?.length > 0 &&
      lastName?.length > 0 && phoneNumber?.length > 0 && companyName?.length > 0 && address?.length > 0 && country && country?.length > 0 &&
      zipcode?.length > 0 && city?.length > 0) ? true : false
    }
    const signup = () => {
      setLoading(true);
        props.signup({
          email: email,
          password1: password,
          password2: password,
          first_name: firstName,
          last_name: lastName,
          phone_number: phoneNumber,
          company: companyName,
          reference: findUs,
          shipping_address: {
            address,
            country,
            zipcode,
            city,
          },               
          use_same_address: true,
        }, () => setLoading(false));
      }
    return (
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address*</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password*</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicFirstName">
            <Form.Label>First name*</Form.Label>
            <Form.Control
              type="name"
              placeholder="Name"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicLastName">
            <Form.Label>Last name*</Form.Label>
            <Form.Control
              type="name"
              placeholder="Name"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPhone">
            <Form.Label>Phone number*</Form.Label>
            <Form.Control
              type="number" 
              placeholder="Cell number"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicComapny">
            <Form.Label>Company*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Company name"
              value={companyName}
              onChange={(e) => {
                setCompanyName(e.target.value);
              }}
            />

          </Form.Group>
            <Form.Label><b>Country*</b></Form.Label>
            <Form.Select  onChange={(e) => setCountry(e.target.value)} aria-label={`${t('adminDashboard.CommonWords.select')}`}>
              {
                countriesList.map((country) => {
                  return (
                    <option key={country.name} value={country.name}>{country.name}</option>
                  )
                })
              }
            </Form.Select>           
          <Form.Group style={{marginTop: 16}} className="mb-3" controlId="formBasicCity">
            <Form.Label>City*</Form.Label>
            <Form.Control
              type="text"
              placeholder="City"
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicZipcode">
            <Form.Label>Zip code*</Form.Label>
            <Form.Control
              placeholder="Zip code"
              value={zipcode}
              onChange={(e) => {
                setZipcode(e?.target?.value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicAddress">
            <Form.Label>Address*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Address"
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicFindus">
            <Form.Label>How did you find us? </Form.Label>
            <Form.Control
              type="text"
              placeholder="e.g. Any Social Media or Customer referral etc."
              value={findUs}
              onChange={(e) => {
                setFindUs(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Link to="/login">Already have an account?</Link>
          </Form.Group>
           <CFormFeedback invalid={errorMessage && errorMessage?.length > 0 ? false : true} style={{color: 'red'}}>{errorMessage}</CFormFeedback> 
          <Button disabled={loading || !isDataValid()} className="login-button" onClick={signup}>
            {loading ? <CSpinner /> : "Submit"}
          </Button>
        </Form>
    )
};
export default RegistrationForm;