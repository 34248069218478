import { useEffect, useState } from 'react'
import 'simplebar/dist/simplebar.min.css';
import '../../AdminStyles.css';
import AddNewSupplyType from '../../components/supply-management/AddNewSupplyType';
import AddNewSupply from '../../components/supply-management/AddNewSupply';
import SupplyManagementHeader from '../../components/supply-management/HeaderComponent';
import AllSupplyTypes from '../../components/supply-management/AllSupplyTypes';
import AllSupplies from '../../components/supply-management/AllSupplies';
import { CCol } from '@coreui/react-pro';
import AllSuppliers from '../../components/supply-management/AllSuppliers';
import { CRow, CToast, CToastBody, CToastHeader, CToaster } from '@coreui/react';
import AddSupplyVisibility from '../../components/supply-management/AddSupplyVisibility';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Utils } from '../../components/utils';

const SupplyManagementScreen = () => {
    const [toast, addToast] = useState<any>(null);
    const {t} = useTranslation();
    const props = useLocation();
    const currentTabString = props?.state?.tab;
    const [selectedTab, setSelectedTab] = useState<0 | 1 | 2 | 3>( currentTabString ? JSON.parse(currentTabString) : 0);
    const CreatedToast = (
        <CToast animation autohide visible delay={3000} onClose={() => {addToast(null)}}>
            <CToastHeader closeButton={true}>         
                <div className="fw-bold me-auto">Created.</div>
                <small>few seconds ago</small>
            </CToastHeader>
            <CToastBody>{t('adminDashboard.CommonWords.item_created_successfully')}</CToastBody>
        </CToast>
    );

    const EditedToast = (
        <CToast animation autohide visible delay={3000} onClose={() => {addToast(null)}}>
            <CToastHeader closeButton>         
                <div className="fw-bold me-auto">Edited.</div>
                <small>few seconds ago</small>
            </CToastHeader>
            <CToastBody>{t('adminDashboard.CommonWords.item_updated_successfully')}</CToastBody>
        </CToast>
    );

    const DeletedToast = (
        <CToast animation autohide visible delay={3000} onClose={() => {addToast(null)}}>
            <CToastHeader closeButton>         
                <div className="fw-bold me-auto">Deleted.</div>
                <small>few seconds ago</small>
            </CToastHeader>
            <CToastBody>Item has been deleted.</CToastBody>
        </CToast>
    );
    const ErrorToast = (
        <CToast animation autohide visible delay={3000} onClose={() => {addToast(null)}}>
            <CToastHeader closeButton>         
                <div className="fw-bold me-auto">Error.</div>
                <small>few seconds ago</small>
            </CToastHeader>
            <CToastBody>Sorry something went wrong!</CToastBody>
        </CToast>
    );
    // useEffect(()=>{
    //     getManualPrices();
    // },[])
    return (
        <div className='website-settings-main-container'>
            <CToaster push={toast} placement="top-end" />
            <SupplyManagementHeader selectedTab={selectedTab} setSelecteTab={setSelectedTab} />
            {
                selectedTab === 0 ?
                <>
                    <CRow>
                        <CCol xs={12} md={6}>
                            <div className='margin-top-24'>
                                <AddNewSupply showCreatedToast={() => addToast(CreatedToast)}/>
                            </div>
                        </CCol>
                        <CCol xs={12} md={6}>
                            <div className='margin-top-24'>
                                <AddSupplyVisibility showEditedToast={() => addToast(EditedToast)} showErrorToast={() => addToast(ErrorToast)} />
                            </div>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol xs={12} md={6}>
                            <div className='margin-top-24'>
                                <AddNewSupplyType showCreatedToast={() => addToast(CreatedToast)}/>
                            </div>
                        </CCol>
                    </CRow>
                    
                </>
                : selectedTab === 1 ?
                    <AllSupplies showEditedToast={() => addToast(EditedToast)} showDeletedToast={() => addToast(DeletedToast)} />

                :  selectedTab === 2 ? <AllSupplyTypes showEditedToast={() => addToast(EditedToast)} showDeletedToast={() => addToast(DeletedToast)} />

                :  <AllSuppliers showEditedToast={() => addToast(EditedToast)} showDeletedToast={() => addToast(DeletedToast)} />
            }
        </div>
    )
};
export default SupplyManagementScreen;
