import { useEffect, useState } from 'react';
import 'simplebar/dist/simplebar.min.css';
import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CSmartTable, CTooltip } from '@coreui/react-pro';
import '../../AdminStyles.css';
import { deleteCustomer } from '../../../services/CustomerService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useNavigate } from 'react-router-dom';
import { CustomerDetails } from '../../../types';
import { t } from "i18next";
import { ADMIN } from '../../../constants';
import { CBadge } from '@coreui/react';
import { Utils } from '../../components/utils';

const CustomersScreen = () => {
  const customers = useSelector((state: RootState) => state.usersSlice.customers);
  const authUser = useSelector((state: RootState) => state.authSlice.user);
  const IS_ADMIN_OR_SUPERADMIN = (authUser.is_superuser || authUser?.groups?.includes(ADMIN));
  const navigation = useNavigate();
  const {getCustomersData, getCountries, returnDateFormat, CustomerColumns} = Utils();
  const [loading, setLoading] = useState<boolean>(false);
  const [activePage, setActivePage] = useState(1); 
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [users, setUsers] = useState<CustomerDetails[]>(customers);
  const [records, setRecords] = useState<number>(customers.length);

  useEffect(()=>{
    getCountries();
  }, [])
  useEffect(() => {
    if(customers.length > 0) {
      setUsers(customers);
      setRecords(customers.length);
    }
  },[customers]);

  const deleteCustomerData = (id: number) => {
    setLoading(true);
    deleteCustomer(id).then((response) => {
      getCustomersData();
    }).catch((e) => {
      setLoading(false);
      console.log('delete customers error: ', e);
    })
  };

  return (
    <div className='website-settings-main-container'>
      <h1><b>{t('adminDashboard.Customers.main_text')}</b></h1>
      <CButton className='margin-bottom-24' onClick={() => navigation('/admin/dashboard/add/user')}>{t('adminDashboard.Customers.add_customer')}</CButton>
      <CSmartTable
        columns={CustomerColumns}
        columnSorter={true}
        tableFilter
        tableFilterPlaceholder={`${t('adminDashboard.CommonWords.search')}`}
        loading={loading}
        items={users}
        itemsPerPage={itemsPerPage}
        itemsPerPageSelect
        pagination={true}
        paginationProps={{
          activePage: activePage,
          pages: Math.ceil(records / itemsPerPage) || 1,
        }}
        tableHeadProps={{
          color: 'info',
        }}
        tableProps={{
          hover: true,
          responsive: true,
          style:{marginTop: 12},
          striped: true,
        }}
        tableBodyProps={{
          className: 'align-middle',
        }}
        onActivePageChange={(activePage) => setActivePage(activePage)}
        onItemsPerPageChange={(itemsPerPage) => {
          setActivePage(1)
          setItemsPerPage(itemsPerPage)
        }}
        scopedColumns={{
          company: (item: CustomerDetails) => {
            const visibleCompanyName = item.company.slice(0, 20)
            const toolTipCompanyName = item.company
            return (
              <td>
                <CTooltip
                    content={toolTipCompanyName.length > 0 ? toolTipCompanyName : ''}
                    placement="top"
                  >
                  <div className="text-center" style={{ maxWidth: 220, wordWrap: 'break-word' }}>
                    {visibleCompanyName}
                  </div>
                </CTooltip>
              </td>
            )
          },
          is_active: (item: CustomerDetails) => {
            return (
              <td>
                <CBadge color={item.is_active ? 'success' : 'danger'}><b>{item.is_active ? "Active" : "Not Active"}</b></CBadge> 
              </td>
            )
          },
          date_joined: (item: CustomerDetails) => {
            return (
              <td>
                { item?.date_joined ? returnDateFormat(item.date_joined) : '' }
              </td>
            )
          },
          action: (item: CustomerDetails) => {
            return (
              <td>
                <CDropdown>
                  <CDropdownToggle className="elv-btn" href="#" style={{width: 110}}>Action</CDropdownToggle>
                  <CDropdownMenu style={{position: 'absolute'}}>
                    <CDropdownItem onClick={(e)=>{navigation('/admin/dashboard/add/user', {state: {customerObject: item, editText: 'Edit User', buttonText: 'Edit'}})}}>{t('adminDashboard.Customers.edit_info')}</CDropdownItem>
                    {IS_ADMIN_OR_SUPERADMIN ? <CDropdownItem  onClick={() => {deleteCustomerData(item.id)}}>{t('adminDashboard.Customers.delete_customer')}</CDropdownItem> : null}
                  </CDropdownMenu>
                </CDropdown>
              </td>
            )
          },
        }}
      />
    </div>
  );
};
export default CustomersScreen;

