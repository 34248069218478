import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ProductBrand, ProductCategory, Supply, Supplier, SupplyType,ProductSubCategory, ExcelDataObject, Usage, ProductItem, Pallet, CategoryQuestion } from '../../types';
export interface ProductsSliceState {
    products: ProductItem[];
    pallets: Pallet[];
    activePalletsCount: number;
    activeProductsCount: number;
    totalProductsCount: number;
    productCategoryQuestions: CategoryQuestion[];
    productBrands: ProductBrand[];
    productCategories: ProductCategory[]; // Product Brand and Categories have the same structure so product category does not need a separate data type.
    productSubCategories: ProductSubCategory[];
    productArticles: string[];
    productSupplies: Supply[];
    suppliers: Supplier[];
    supplyTypes: SupplyType[];
    manualProductPrices: ExcelDataObject[];
    productUsage: Usage[];
}
const initialState: ProductsSliceState = {
  products: [], // defauly object.
  pallets: [],
  productBrands: [],
  productCategoryQuestions: [],
  productCategories: [],
  productSubCategories: [],
  productArticles: [],
  productSupplies: [],
  suppliers: [],
  supplyTypes: [],
  manualProductPrices: [],
  productUsage: [],
  totalProductsCount: 0,
  activePalletsCount: 0,
  activeProductsCount: 0,
};

const ProductSlice = createSlice({
    name: 'productSlice',
    initialState,
    reducers: {
      productRehydrateState(state, action: PayloadAction<any>) {
        return { ...state, ...action.payload };
      },
      setProducts(state, action: PayloadAction<any>) {
        state.products = action.payload;
      },
      setPalletProducts(state, action: PayloadAction<any>) {
        state.pallets = action.payload;
      },
     
      setActivePalletsCount(state, action: PayloadAction<any>) {
        state.activePalletsCount = action.payload;
      },

      setActiveProductsCount(state, action: PayloadAction<any>) {
        state.activeProductsCount = action.payload;
      },

      setProductsCount(state, action: PayloadAction<any>) {
        state.totalProductsCount = action.payload;
      },
      setProductBrands(state, action: PayloadAction<any>) {
        state.productBrands = action.payload;
      },
      setProductCategoryQuestions(state, action: PayloadAction<any>) {
        state.productCategoryQuestions = action.payload;
      },

      setProductCategories(state, action: PayloadAction<any>) {
        state.productCategories = action.payload;
      },
      setProductSubCategories(state, action: PayloadAction<any>) {
        state.productSubCategories = action.payload;
      },
      setProductArticles(state, action: PayloadAction<any>) {
        state.productArticles = action.payload;
      },
      setProductSupplies(state, action: PayloadAction<any>) {
        state.productSupplies = action.payload;
      },
      setSuppliers(state, action: PayloadAction<any>) {
        state.suppliers = action.payload;
      },
      setSupplyTypes(state, action: PayloadAction<any>) {
        state.supplyTypes = action.payload;
      },
      setManualProductPrices(state, action: PayloadAction<any>) {
        state.manualProductPrices = action.payload;
      },
      setUsage(state, action: PayloadAction<any>) {
        state.productUsage = action.payload;
      },
    },
  })
  
  export const {
    setProducts, setProductBrands, setProductCategories, setPalletProducts, setActiveProductsCount,
    setProductArticles, setProductSupplies, setSuppliers, setSupplyTypes, setActivePalletsCount, setUsage,
    setProductSubCategories, setManualProductPrices, setProductsCount, setProductCategoryQuestions,
    productRehydrateState
  } = ProductSlice.actions;
  export default ProductSlice.reducer;