import { CRow, CCol, CButton, CCard, CCardBody, CCardText, CFormLabel, CFormInput, CFormCheck, CBadge } from "@coreui/react"
import { Carousel } from 'react-responsive-carousel';
import { LoggedinUser, ProductDetailedSetArticle, ProductItem, Package, CategoryQuestion, DropDownItem } from "../../types";
import { useCallback, useState } from "react";
import ImageViewer from 'react-simple-image-viewer';
import ProgressBar from "@ramonak/react-progress-bar";
import EmptyImage from '../../assets/images/empty-image.jpg';
import { ADMIN, BACK_OFFICE_GROUP_ID, CUSTOMER_GROUP_ID, SALES_GROUP_ID, SUPER_ADMIN, SUPPLIER_GROUP_ID } from "../../constants";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { addToCart, removeFromCart } from '../../redux/slices/CustomerCartSlice';
import { formattedPrice } from '../../constants';

import '../CustomerStyles.css';
import { editProductData } from "../../services/ProductService";
import Divider from "../../admin/components/divider";
import EditProductForm from "./EditProductForm";
import { useTranslation } from "react-i18next";
import { Utils } from "../../admin/components/utils";

interface ProductDetailsCardComponentProps {
    images: any;
    productEditable: boolean;
    product: ProductItem | ProductDetailedSetArticle;
    setProduct?: (productItem: ProductItem | ProductDetailedSetArticle) => void;
    combinedProductId?: number[];
    showButton: boolean;
}

const ProductDetailsCardComponent = (props: ProductDetailsCardComponentProps) => {
    const { productEditable, images, product, combinedProductId, showButton, setProduct} = props;
    const { t } = useTranslation();
    const {getLink} = Utils();
    const authUser: LoggedinUser = useSelector((state: RootState) => state.authSlice.user);
    const categoryQuestions: CategoryQuestion[] = useSelector((state: RootState) => state.productSlice.productCategoryQuestions);
    const scalingList = product?.metadata?.scaling_list;
    const packageItemString = localStorage.getItem('packageItem');
    const packageItem: Package = packageItemString ? JSON.parse(packageItemString) : null;
    const productCartSelector = (packageId: number) => (state: RootState) => state.CustomerCartSlice[packageId]?.productCart;
    const productCart = useSelector(productCartSelector(packageItem?.id)) || [];
    const question_answer_list =  product?.metadata?.question_answer_list;
    const dispatch = useDispatch();
    const [imageViewerUrl, setImageViewerUrl] = useState<string>('');
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    const [isViewerOpen, setIsViewerOpen] = useState<boolean>(false);
    const [editProduct, setEditProduct] = useState<boolean>(false);
    // Section: Edit product fields.

    // End section

    const getCategoryQuestion = (category_id: number | undefined, question_number: number) => {
        return categoryQuestions.filter((question: CategoryQuestion) => {
            return question.category == category_id && question.number == question_number
        })[0]
    }

    const openImageViewer = useCallback(() => {
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setIsViewerOpen(false);
    };

    // Update Cart and Price accordingly
    const updateCartWithPrice = (packageId: number, id: number, selling_price: number, combined_products?: number[]) => {
        const indexInCart = productCart.indexOf(id);
        if (indexInCart !== -1) {
            dispatch(removeFromCart({ packageId: packageId, id: id, selling_price: selling_price, combined_products: combined_products }));
        } else {
            dispatch(addToCart({ packageId: packageId, id: id, selling_price: selling_price, combined_products: combined_products }));
        }
    }

    // const handleFileChange = (e: any) => {
    //     uploadFile(e.target.files[0])
    // }
    // const uploadFile = async (file: any) => {
    //     const formData = new FormData();
    //     formData.append('image', file);
    
    //     try {
    //       const response = await fetch(`https://5vymjwmn02.execute-api.eu-central-1.amazonaws.com/upload?lager=${product.id}`, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'multipart/form-data',
    //             'x-api-key': 'YYltflV8LP492bltrm2XC4jWVKauVEPw4rYhHNyj',
    //             'Access-Control-Allow-Origin': '*',
    //         },
    //         body: formData,
    //       });
    //       const result = await response.json();
    //       console.log('Success:', result);
    //     } catch (error) {
    //       console.error('Error:', error);
    //     }
    // };
    return (
        isViewerOpen ? (
            <ImageViewer
                src={[imageViewerUrl]}
                currentIndex={0}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
            />
        )
        :
        (
            <CRow>
                <CCol className="col-sm-4 margin-top-24">
                    {images == undefined || images?.length == 0 ?
                        <div>
                            <img src={EmptyImage} className={"aspect-ratio-style"} alt="logo" />
                        </div>
                        :
                        <>
                            <Carousel selectedItem={currentQuestionIndex} showThumbs={false} showStatus={false} onClickItem={(index: number) => { setImageViewerUrl(images[currentQuestionIndex]?.url); openImageViewer() }} onChange={(index: number) => { setCurrentQuestionIndex(index) }}>
                                {
                                    images && images
                                    ?.filter((imageItem: any) => {
                                        return (imageItem?.url?.split('&')[1]?.split('=')[1]?.includes('P') || imageItem?.url?.split('&')[1]?.split('=')[1]?.includes('0'))
                                    })?.map((imageItem: any) => {
                                        return (
                                            <div>
                                                <img src={imageItem.url} className={"aspect-ratio-l-style"} alt="logo" />
                                            </div>
                                        )
                                    })
                                }
                            </Carousel>
                        </>
                    }
                </CCol>
                <CCol className="col-sm-8 margin-top-24">
                    <CCard>
                        <CCardBody>                        
                            {
                                productEditable || editProduct ?
                                    <EditProductForm
                                        editProduct={editProduct}
                                        product={product}
                                        productEditable={productEditable}
                                        setProduct={setProduct && setProduct}
                                        setEditProduct={setEditProduct}
                                    />
                                : null  
                            }
                            {
                                !editProduct ?
                                <>
                                    <CRow>
                                        <CCol className="col-sm-6">
                                            {!authUser?.groups?.includes(CUSTOMER_GROUP_ID) ? <CBadge className="margin-left-12 margin-top-12" color={product?.is_sold ? "danger" : "success"} style={{fontSize: 16}}>{product?.is_sold ? product?.order_id ? "SOLD" : "OFFERED" : "Available"}</CBadge> : null }
                                            {/* <CCardText className="margin-left-12"><h6>{t('adminDashboard.CommonWords.status')}: {product?.is_sold ? "Sold" : "Available"}</h6></ CCardText> */}
                                            <ul className="list-group list-group-flush margin-top-12">
                                                <li className="list-group-item margin-top-12"><CCardText><div><h6>{t('adminDashboard.Products.product_id')}: {product?.id}</h6></div></ CCardText></li>
                                                <li className="list-group-item margin-top-12"><CCardText><div><h6>{t('adminDashboard.Products.grade_point')}: {Math.round(Number(product?.final_points))}</h6></div></ CCardText></li>
                                                <li className="list-group-item  margin-top-12"><CCardText><div><h6>{t('adminDashboard.CommonWords.model')}: {product?.model}</h6></div></ CCardText></li>
                                                {(Number(product?.final_points) > 0 && (authUser?.groups?.includes(CUSTOMER_GROUP_ID) || (authUser?.groups?.includes(SALES_GROUP_ID)))) ? null : <li className="list-group-item margin-top-12"><CCardText><div><h6>{t('adminDashboard.Products.final_grade')}: {product?.final_grade}</h6></div></ CCardText></li>} 
                                                
                                                {
                                                    authUser?.groups?.includes(CUSTOMER_GROUP_ID) || authUser?.groups?.includes(SUPPLIER_GROUP_ID) ?
                                                        <li className="list-group-item margin-top-12"><CCardText><div><h6>{t('adminDashboard.Products.article')}: {product?.article}</h6></div></ CCardText></li>
                                                    :
                                                        <li className="list-group-item margin-top-12 url-decoration"><CCardText><div><h6 
                                                            onClick={() => {
                                                                const link = product.link ? product.link : getLink(product.model || '');
                                                                if (link?.length > 0) {
                                                                    window.open(link.startsWith('http') ? link : `https://${link}`, '_blank', 'noopener,noreferrer');
                                                                }
                                                            }}
                                                        >{t('adminDashboard.Products.article')}: {product?.article}</h6></div></ CCardText></li>
                                                }
                                               
                                                { !authUser?.groups?.includes(CUSTOMER_GROUP_ID) ? <li className="list-group-item margin-top-12"><CCardText><div><h6>{t('adminDashboard.SupplyManagement.supply')}: {product?.supply}</h6></div></ CCardText></li> : null}
                                                {!authUser?.groups?.includes(SUPPLIER_GROUP_ID) ?
                                                    <li className="list-group-item margin-top-12"><CCardText><div><h6>{t('adminDashboard.Products.selling_price')}: {formattedPrice(Number(product?.selling_price))} </h6></div></ CCardText></li>
                                                    :
                                                    <li className="list-group-item margin-top-12"><CCardText><div><h6>{t('adminDashboard.Products.buying_price')}: {formattedPrice(Number(product?.buying_price))}</h6></div></ CCardText></li>}
                                            </ul>
                                        </CCol>
                                        <CCol className="col-sm-6">
                                            <ul className="list-group list-group-flush margin-top-12">
                                                <li className="list-group-item  margin-top-12"><CCardText><div><h6>{t('adminDashboard.Products.brand')}: {product?.brand}</h6></div></ CCardText></li>
                                                <li className="list-group-item margin-top-12"><CCardText><div><h6>{t('adminDashboard.Products.category')}: {product?.sub_category}</h6></div></ CCardText></li>
                                                <li className="list-group-item margin-top-12"><CCardText><div><h6>{t('adminDashboard.Products.remarks')}: {product?.metadata?.remarks}</h6></div></ CCardText></li>
                                                <li className="list-group-item margin-top-12"><CCardText><div><h6>{t('adminDashboard.Products.usage')}: {product?.usage?.name}</h6></div></ CCardText></li>
                                                {
                                                    (authUser?.groups?.includes(BACK_OFFICE_GROUP_ID) || authUser?.groups?.includes(ADMIN) || authUser?.groups?.includes(SUPER_ADMIN)) ?
                                                        <li className="list-group-item margin-top-12"><CCardText><div><h6>{t('adminDashboard.Products.supplier_grade')}: {product?.supplier_grade}</h6></div></ CCardText></li>
                                                    : null
                                                }
                                                {
                                                    product?.is_sold ?
                                                    <li className="list-group-item margin-top-12"><CCardText><div><h6>{t('adminDashboard.Products.online_price')}: {product?.selling_online_price && formattedPrice(Number(product.selling_online_price))}</h6></div></ CCardText></li>
                                                    :
                                                    <li className="list-group-item margin-top-12"><CCardText><div><h6>{t('adminDashboard.Products.online_price')}: {product?.current_online_price && formattedPrice(Number(product.current_online_price))}</h6></div></ CCardText></li>
                                           
                                                }
                                            </ul>
                                        </CCol>
                                        {
                                            !authUser?.groups?.includes(CUSTOMER_GROUP_ID) && !authUser?.groups?.includes(SUPPLIER_GROUP_ID) ?
                                            <>
                                             <CCol className="col-sm-6">
                                                <ul className="list-group list-group-flush margin-top-12">
                                                    <li className="list-group-item  margin-top-12"><div><h6>Active: {!product?.is_deleted ? "Yes" : "No"}</h6></div></li>
                                                    {product?.metadata?.broken_glass ? <li className="list-group-item margin-top-12"><CCardText><div><h6>Glass Broke: {product?.metadata?.broken_glass ? "Yes" : "No"}</h6></div></ CCardText></li> : null}
                                                    
                                                </ul>
                                            </CCol>
                                            <CCol className="col-sm-6">
                                                <ul className="list-group list-group-flush margin-top-12">
                                                    {product?.metadata?.broken_display ? <li className="list-group-item margin-top-12"><CCardText><div><h6>Display Broke: {product?.metadata?.broken_display ? "Yes" : "No"}</h6></div></ CCardText></li> : null}
                                                    {product?.metadata?.broken_door ? <li className="list-group-item margin-top-12"><CCardText><div><h6>Door Broke: {product?.metadata?.broken_door? "Yes" : "No"}</h6></div></ CCardText></li> : null}
                                                </ul>
                                            </CCol>
                                            </> : null
                                        }
                                       
                                    </CRow>
                                    <CRow>
                                        {
                                           question_answer_list && Object.keys(question_answer_list).filter(question_number_key => {
                                                return(!(product?.metadata?.image_list?.includes(Number(question_number_key))) &&
                                                ((getCategoryQuestion(product?.category, Number(question_number_key))?.yes_text.length > 0) 
                                                || (getCategoryQuestion(product?.category, Number(question_number_key))?.no_text.length > 0 )))
                                            })?.map((question_number) => {
                                                return (
                                                    <CCol className="col-sm-6" style={{marginTop: 24}} key={question_number}>
                                                        <CCardText>
                                                            <div>
                                                                {
                                                                    question_answer_list[question_number] == 'No' ?
                                                                        getCategoryQuestion(product?.category, Number(question_number)).no_text 
                                                                        : 
                                                                        getCategoryQuestion(product?.category, Number(question_number)).yes_text 
                                                                }
                                                            </div>

                                                            {
                                                                scalingList[question_number] ?
                                                                <>
                                                                    <div>
                                                                        Scale:  {scalingList[question_number]}/{getCategoryQuestion(product?.category, Number(question_number)).scale_type}
                                                                    </div>
                                                                    <div>
                                                                        <ProgressBar completed={`${scalingList[question_number]}`} maxCompleted={Number(getCategoryQuestion(product?.category, Number(question_number)).scale_type)} bgColor="#23B2D9" width="30%" customLabel=" "/>
                                                                    </div>
                                                                </>
                                                                : null
                                                            }
                                                        </ CCardText>
                                                    </CCol>
                                                )})
                                        }
                                    </CRow>
                                </>
                                : null
                            }
                        </CCardBody>
                    </CCard>
                    
                    {
                        authUser?.groups?.includes(CUSTOMER_GROUP_ID) && showButton ?
                            <CCol className="py-3">
                                {
                                    packageItem && packageItem.status == 'A' ?
                                        <div>
                                            <CButton
                                                className='elv-btn'
                                                variant="outline"
                                                shape="square"
                                                onClick={() => {
                                                    if (packageItem && product && product.id !== undefined && product.selling_price !== undefined) {
                                                        updateCartWithPrice(packageItem?.id, product?.id, product?.selling_price, combinedProductId);
                                                    }
                                                }}
                                            >
                                                {
                                                    product?.id !== undefined && productCart?.includes(product?.id) ? t('adminDashboard.Products.remove_from_cart') : t('adminDashboard.Products.add_to_cart')
                                                }
                                            </CButton>
                                        </div>
                                    :
                                    null
                                }
                            </CCol>
                        :
                        null
                    }
                </CCol>
                <Divider color={"grey"} thickness={1} margin={24} />
                <CCol xs={12} className="margin-top-12">
                    <CRow>
                        {
                            images && images?.map((imageItem: any, index: number) => {
                                if(!imageItem.url?.split('&')[1]?.split('=')[1]?.includes('P') && !imageItem.url?.split('&')[1]?.split('=')[1]?.includes('0')) {
                                    return (
                                    <CCol xs={3} className="margin-top-24">
                                        <img src={imageItem.url} className={"aspect-ratio-style"} alt="logo" onClick={(e) => { setImageViewerUrl(imageItem.url); openImageViewer() }}/>
                                        {
                                            question_answer_list && Object.keys(question_answer_list).filter(question_number_key => {
                                                return((question_number_key == images[index]?.question_number) &&
                                                (getCategoryQuestion(product?.category, Number(question_number_key))?.yes_text.length > 0 
                                                || getCategoryQuestion(product?.category, Number(question_number_key))?.no_text.length > 0 ))
                                            })?.map((question_number) => {
                                                return (
                                                    <CCol className="col-sm-6" style={{marginTop: 24}} key={question_number}>
                                                        <CCardText className="center">
                                                            <div>
                                                                {
                                                                    question_answer_list[question_number] == 'No' ?
                                                                        getCategoryQuestion(product?.category, Number(question_number)).no_text 
                                                                        : 
                                                                        getCategoryQuestion(product?.category, Number(question_number)).yes_text 
                                                                }
                                                            </div>
                                                            {
                                                                scalingList[question_number] ?
                                                                <>
                                                                    <div>
                                                                        Scale:  {scalingList[images[index].question_number]}/{getCategoryQuestion(product?.category, Number(question_number)).scale_type}
                                                                    </div>
                                                                    <div>
                                                                        <ProgressBar completed={`${scalingList[images[index].question_number]}`} maxCompleted={Number(getCategoryQuestion(product?.category, Number(question_number)).scale_type)} bgColor="#23B2D9" width="100%" customLabel=" "/>
                                                                    </div>
                                                                </>
                                                                : null
                                                            }
                                                        </ CCardText>
                                                    </CCol>
                                                )}
                                            )
                                        }
                                    </CCol>
                                )}
                            })
                            
                        }
                    </CRow>
                </CCol>

            </CRow>
        )
    )
};
export default ProductDetailsCardComponent;
